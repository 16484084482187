import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SnackBar } from 'app/shared/modules/snack-bar/snack-bar.service';

import { BehaviorSubject, Observable, catchError, map, take, throwError } from 'rxjs';

import { environment } from 'environments/environment';

import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { ApiService } from '../api.service';
import { HttpModule } from 'app/shared/http/http.module';
import { FlightClubError } from './flight-club-error';

@Injectable({
    providedIn: HttpModule,
})
export class FlightClubApiService extends ApiService {

    private _accessTokenSub: BehaviorSubject<string>;

    constructor(private http: HttpClient, private localStorage: LocalStorageService, protected override snackBar: SnackBar) {
        super(snackBar);

        const serverRoot = environment.flightClubServer;
        this._apiURL = serverRoot + '/v3';
        this._accessTokenSub = new BehaviorSubject<string>(this.accessToken);
    }

    get<O>(url: string): Observable<O | never> {
        return this.http
            .get<O>(url, { headers: this.headers, observe: 'response' })
            .pipe(take(1), map(this.extractRefreshToken.bind(this)), catchError(this.dealWithError.bind(this)));
    }

    post<I, O>(url: string, resource: I): Observable<O | never> {
        return this.http
            .post<O>(url, resource, { headers: this.headers, observe: 'response' })
            .pipe(take(1), map(this.extractRefreshToken.bind(this)), catchError(this.dealWithError.bind(this)));
    }

    put<I, O>(url: string, resource: I): Observable<O | never> {
        return this.http
            .put<O>(url, resource, { headers: this.headers, observe: 'response' })
            .pipe(take(1), map(this.extractRefreshToken.bind(this)), catchError(this.dealWithError.bind(this)));
    }

    patch<I, O>(url: string, resource: I): Observable<O | never> {
        return this.http
            .patch<O>(url, resource, { headers: this.headers, observe: 'response' })
            .pipe(take(1), map(this.extractRefreshToken.bind(this)), catchError(this.dealWithError.bind(this)));
    }

    delete<O>(url: string): Observable<O | never> {
        return this.http
            .delete<O>(url, { headers: this.headers, observe: 'response' })
            .pipe(take(1), map(this.extractRefreshToken.bind(this)), catchError(this.dealWithError.bind(this)));
    }

    get accessToken(): string {
        return this.localStorage.get('accessToken');
    }

    get accessTokenSub(): Observable<string> {
        return this._accessTokenSub.asObservable()
    }

    get headers(): HttpHeaders {
        const _headers = {
            'Content-Type': 'application/json'
        };
        if (this.accessToken) {
            _headers['Authorization'] = `Bearer ${this.accessToken}`
        }
        return new HttpHeaders(_headers);
    }

    extractRefreshToken<T>(response: HttpResponse<T>): T {
        try {
            this.processAuthorizationHeader(response.headers)
        } catch (error) { 
            console.error(error)
        }
        return response.body
    }

    processAuthorizationHeader(headers: HttpHeaders) {
        const authHeader = headers.get("Authorization")
        if (authHeader != null) {
            if(authHeader.length > "Bearer ".length) {
                const jwt = authHeader.substring("Bearer ".length)
                this.setAccessToken(jwt)
            }
            else {
                this.setAccessToken(null)
            }
        }
    }
    
    setAccessToken(accessToken: string) {
        if (accessToken == null) {
            this.localStorage.delete('accessToken');
        } else {
            this.localStorage.put('accessToken', accessToken);
        }
        this._accessTokenSub.next(accessToken)
    }

    override dealWithError(error: HttpErrorResponse): Observable<any> {
        
        try {
            console.log(error.headers)
            this.processAuthorizationHeader(error.headers)
        } catch (error) { 
            console.error(error)
        }

        const flightClubError = error.error as FlightClubError;
        switch (flightClubError.code) {
            case 401:
            case 404:
            case 500:
                break;
            default:
                this.snackBar.open(`${flightClubError.errorMessage}: ${flightClubError.reason}`, 'Ok', 5000);
        }
        return throwError(() => error);
    }
}
