import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FlightClubApiService } from '../flight-club-api.service';
import { User } from '../user/user';
import { HttpModule } from 'app/shared/http/http.module';

@Injectable({
    providedIn: HttpModule,
})
export class GoogleSignInService {
    resourceEndpoint = 'google';

    constructor(private fcApiService: FlightClubApiService) {}

    validateToken(token: string): Observable<User | never> {
        return this.fcApiService.get<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/validate/${token}`)
    }

    unlink(): Observable<User | never> {
        return this.fcApiService.get<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/unlink`);
    }
}
