import { Observable } from 'rxjs';

import { FlightClubApiService } from './flight-club-api.service';
import { DatabaseResource } from './database-resource';
import { Page } from './page';

export abstract class FlightClubBasicCRUDService<T extends DatabaseResource> {
    protected abstract resourceEndpoint: string;

    constructor(protected fcApiService: FlightClubApiService) { }

    getOne(id: string, queryParams: any = null): Observable<T | never> {
        const queryString = this.fcApiService.constructQueryString(queryParams);
        const url = `${this.fcApiService.apiURL}/${this.resourceEndpoint}/${id}` + (queryString ? `?${queryString}` : ``)
        return this.fcApiService.get<T>(url);
    }

    getPage(page: number, size: number): Observable<Page<T>> {
        const queryString = this.fcApiService.constructQueryString({ page, size });
        return this.fcApiService.get<Page<T>>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}?${queryString}`)
    }

    getAll(queryParams: any = null): Observable<T[] | never> {
        const queryString = this.fcApiService.constructQueryString(queryParams);
        const url = `${this.fcApiService.apiURL}/${this.resourceEndpoint}` + (queryString ? `?${queryString}` : ``)
        return this.fcApiService.get<T[]>(url);
    }

    getSubResource<A>(subResource: string, queryParams: any = null): Observable<A | never> {
        const queryString = this.fcApiService.constructQueryString(queryParams);
        const url = `${this.fcApiService.apiURL}/${this.resourceEndpoint}/${subResource}` + (queryString ? `?${queryString}` : ``)
        return this.fcApiService.get<A>(url);
    }

    getAllProjected(): Observable<T[] | never> {
        return this.fcApiService.get<T[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/projected`);
    }

    create(resource: T): Observable<T | never> {
        return this.fcApiService.post<T, T>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}`, resource);
    }

    update(id: string, resource: T): Observable<T | never> {
        return this.fcApiService.put<T, T>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${id}`, resource);
    }

    delete(id: string): Observable<T | never> {
        return this.fcApiService.delete(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${id}`);
    }
}