import { Injectable } from '@angular/core';
import { HttpModule } from 'app/shared/http/http.module';
import { InstanceFactory } from '../interfaces/instance-factory';
import { User } from './user';

@Injectable({
    providedIn: HttpModule,
})
export class UserFactory implements InstanceFactory<User> {
    constructor() {}

    create(model: User): User {
        if (model == null) {
            return null;
        }
        return new User(
            model.id,
            model.role || [],
            model.apiTokens,
            model.favouriteLocations,
            model.details,
            model.coupons,
            model.livestreamConfig,
            model.subscriptions
        );
    }
}
