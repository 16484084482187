import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { catchError, map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AppServicesModule } from '../modules/app-services.module';

import { UserService } from '../http/flight-club/user/user.service';
import { User } from '../http/flight-club/user/user';
import { UserFactory } from '../http/flight-club/user/user.factory';
import { FlightClubApiService } from '../http/flight-club/flight-club-api.service';
import { Jwt } from '../interfaces/jwt';

@Injectable({
    providedIn: AppServicesModule,
})
export class AuthService {
    private user: BehaviorSubject<User>;
    private user$: Observable<User>;

    constructor(
        private fcApiService: FlightClubApiService,
        private userService: UserService,
        private userFactory: UserFactory,
        private location: Location
    ) {
        this.user = new BehaviorSubject<User>(new User());
        this.user$ = this.user; // TS automatically converts from Subject to Observable
    }

    checkFragment(fragment: string, initial = false): Observable<User> {
        // the initial one (which comes from app component) always needs to do a getCurrent()
        // after that, we don't need to do it again unless there's a new fragment
        if (!initial && (fragment == null || fragment == '')) {
            return this.user$;
        }

        // what if i fuck up and fragment has multiple fragments in it? like
        // ac53de2f-785c-426d-93c7-97fa0178a636#ac53de2f-785c-426d-93c7-97fa0178a636#ac53de2f-785c-426d-93c7-97fa0178a636
        // take first one and scrub the rest

        if (fragment != null && fragment != '') {
            if (fragment.indexOf('#') > -1) {
                const fragments = fragment.split('#').filter((_frag) => _frag.length == 36);
                if (fragments.length > 0) {
                    fragment = fragments[fragments.length - 1];
                }
            }

            if (fragment != null && fragment.length >= 36) {
                this.fcApiService.setAccessToken(fragment);
            }
        }

        const currentUser$ = this.fcApiService.accessTokenSub.pipe(
            map(jwt => this.parseUserFromJwt(jwt)),
            tap(() => this.location.replaceState(this.location.path(false))),
            catchError(_ => of(new User())),
            tap(this.setUser.bind(this))
        )

        return currentUser$
    }

    parseUserFromJwt(jwt: string): User {
        const payload = jwt.split(".")[1].replace("_", "/")
        const decodedPayload = JSON.parse(window.atob(payload)) as Jwt
        return this.userFactory.create(JSON.parse(decodedPayload.user))
    }

    getUser(): Observable<User> {
        return this.user$;
    }

    setUser(_user: User) {
        this.user.next(this.userFactory.create(_user));
    }

    logout(): void {
        this.userService
            .logout()
            .pipe(take(1))
            .subscribe((res) => {
                this.fcApiService.setAccessToken(null);
                this.setUser(User.getTemporaryUser());
            });
    }

}
