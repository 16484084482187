import { DatabaseResource } from '../database-resource';
import { Location } from '../location/location';
import { ApiToken } from './api-token';
import { FCSubscription } from './fc-subscription';
import { LivestreamConfig } from './livestream-config';
import { Role } from './role';
import { UserCoupon } from './user-coupon';
import { UserDetails } from './user-details';

export class User extends DatabaseResource {
    constructor(
        id: string = null,
        public role: Role[] = [],
        public apiTokens: ApiToken[] = [],
        public favouriteLocations: Location[] = [],
        public details: UserDetails = new UserDetails(),
        public coupons: UserCoupon[] = [],
        public livestreamConfig: LivestreamConfig = new LivestreamConfig(),
        public subscriptions: FCSubscription[] = []
    ) {
        super(id);
    }

    hasRole(roleName: string): boolean {
        const role = getRoleValue(roleName);
        return this.role.includes(role);
    }

    hasAnyRole(roles: string[]): boolean {
        return roles.map((r) => this.hasRole(r)).reduce((acc, cur) => acc || cur, false);
    }

    isAdmin(): boolean {
        return this.hasRole(Role.ADMIN);
    }

    static getTemporaryUser(): User {
        return new User(null, [], [], [], null, [], null, []);
    }
}

function getRoleValue(enumValue: string): Role | null {
    const keys = Object.keys(Role).filter((x) => Role[x] == enumValue);
    return keys.length > 0 ? (keys[0] as Role) : null;
}
