import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpModule } from 'app/shared/http/http.module';
import { FlightClubBasicCRUDService } from '../flight-club-basic-crud.service';
import { Location } from '../location/location';
import { ApiToken } from './api-token';
import { LivestreamLaunch } from './livestream-launch';
import { SavedSimulation } from './saved-simulation';
import { User } from './user';
import { UserCoupon } from './user-coupon';
import { FlightClubApiService } from '../flight-club-api.service';
import { LocalStorageService } from 'app/shared/services/local-storage.service';
import { FlightClubError } from '../flight-club-error';
import { LivestreamConfig } from './livestream-config';

@Injectable({
    providedIn: HttpModule,
})
export class UserService extends FlightClubBasicCRUDService<User> {
    resourceEndpoint = 'user';

    constructor(protected override fcApiService: FlightClubApiService, private localStorage: LocalStorageService) {
        super(fcApiService);
    }

    getCurrent(): Observable<User | never> {
        return this.fcApiService.get<User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/current`).pipe(
            catchError((e: FlightClubError) => {
                if (e.code == 401) {
                    this.localStorage.delete("accessToken")
                }
                return throwError(() => e)
            })
        );
    }

    logout(): Observable<void | never> {
        return this.fcApiService.get<void>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/logout`);
    }

    /*
        Saved Simulations
    */

    getSavedSimulations(userId: string): Observable<SavedSimulation[]> {
        return this.fcApiService
            .get<SavedSimulation[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/simulations`);
    }

    saveSimulation(userId: string, simulation: SavedSimulation): Observable<User | never> {
        return this.fcApiService.post<SavedSimulation, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/simulations`, simulation);
    }

    deleteSimulation(userId: string, simulationId: string): Observable<User | never> {
        return this.fcApiService.delete(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/simulations/${simulationId}`);
    }

    /*
        Favourite Locations
    */

    getFavouriteLocations(userId: string): Observable<Location[]> {
        return this.fcApiService
            .get<Location[]>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations`);
    }

    addFavouriteLocation(userId: string, location: Location): Observable<User | never> {
        return this.fcApiService
            .post<Location, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations`, location);
    }

    updateFavouriteLocation(userId: string, locationId: string, location: Location): Observable<User | never> {
        return this.fcApiService
            .patch<Location, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations/${locationId}`, location);
    }

    deleteFavouriteLocation(userId: string, locationId: string): Observable<User | never> {
        return this.fcApiService.delete(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations/${locationId}`);
    }

    reorderFavouriteLocations(userId: string, locations: Location[]): Observable<User | never> {
        return this.fcApiService.put<Location[], User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/favourite-locations`, locations);
    }

    /*
        API Tokens
    */

    addApiToken(userId: string, apiToken: ApiToken): Observable<User | never> {
        return this.fcApiService.post<ApiToken, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/api-tokens`, apiToken);
    }

    deleteApiToken(userId: string, token: string): Observable<User | never> {
        return this.fcApiService.delete(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/api-tokens/${token}`);
    }

    /*
        Livestreaming
    */

    getLivestreamConfig(userId: string): Observable<LivestreamConfig> {
        return this.fcApiService.get<LivestreamConfig>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/livestream`);
    }

    saveUnlockedLaunches(userId: string, livestreamLaunches: LivestreamLaunch[]): Observable<User | never> {
        return this.fcApiService.post<LivestreamLaunch[], User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/livestream/unlockedLaunches`, livestreamLaunches);
    }

    setLivestreamBrand(userId: string, brand: string): Observable<User | never> {
        return this.fcApiService.put<string, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/livestream/brand`, brand);
    }

    /*
        User Coupons
    */

    addCoupon(userId: string, coupon: UserCoupon): Observable<User | never> {
        return this.fcApiService.post<UserCoupon, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/coupons`, coupon);
    }

    deleteCoupon(userId: string, couponId: string): Observable<User | never> {
        return this.fcApiService.delete(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/coupons/${couponId}`);
    }

    /*
        Stripe
    */

    setStripeCustomerId(userId: string, stripeCustomerId: string): Observable<User | never> {
        return this.fcApiService.put<string, User>(`${this.fcApiService.apiURL}/${this.resourceEndpoint}/${userId}/stripeCustomerId`, stripeCustomerId);
    }
}
