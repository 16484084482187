import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, from } from 'rxjs';
import { take } from 'rxjs/operators';

import { GoogleAuthProvider, getAuth, signOut, signInWithPopup } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { AuthService } from './auth.service';
import { GoogleSignInService } from '../http/flight-club/google/google-sign-in.service';
import { AppServicesModule } from '../modules/app-services.module';
import { SnackBar } from '../modules/snack-bar/snack-bar.service';

@Injectable({
    providedIn: AppServicesModule,
})
export class FirebaseService {
    /*
        Firebase Docs:
        https://firebase.google.com/docs/auth/web/google-signin#web-version-9_6

        AngularFire: (should be using this instead of bare firebase!)
        https://github.com/angular/angularfire
    */

    private _firebaseValidationState: FirebaseValidationState;
    private _validatingTokenSubject = new BehaviorSubject(false);

    constructor(
        private auth: AuthService,
        private googleSignInService: GoogleSignInService,
        private snackBar: SnackBar
    ) {
        this._firebaseValidationState = {
            validating: false,
            isLoggedIn: false,
        };
        this.initialise();
    }

    initialise() {

        const firebaseConfig = {
            apiKey: 'AIzaSyA4c4Qgka7Jn3Mv-2b6bBwfhpKWByL4M8k',
            authDomain: 'flightclubio.firebaseapp.com',
            databaseURL: 'https://flightclubio.firebaseio.com',
            projectId: 'flightclubio',
            storageBucket: 'flightclubio.appspot.com',
            messagingSenderId: '698533022735',
            appId: '1:698533022735:web:1a9c325337ef5ce756677a',
            measurementId: 'G-FY4XWE0RR2',
        };

        initializeApp(firebaseConfig);
    }

    logout() {
        from(signOut(getAuth()))
            .pipe(take(1))
            .subscribe({
                next: () => this.notify(false, false),
                error: () => this.notify(false, false),
            });
    }

    public tokenValidationChange(): Observable<boolean> {
        return this._validatingTokenSubject;
    }

    get validationState(): FirebaseValidationState {
        return this._firebaseValidationState;
    }

    signInWithGoogleAuthProvider(scopes: string[]): void {
        const provider = new GoogleAuthProvider();
        scopes.forEach((scope) => provider.addScope(scope));
        provider.setCustomParameters({
            prompt: 'select_account',
        });
        from(signInWithPopup(getAuth(), provider)).subscribe({
            next: (userCredential) => this.nextAuthStateChange(userCredential.user),
            error: (err) => console.error(err),
        });
    }

    nextAuthStateChange(user) {
        this.notify(true, false);

        if (user && user['accessToken']) {
            this.googleSignInService
                .validateToken(user['accessToken'])
                .pipe(take(1))
                .subscribe({
                    next: (user) => {
                        this.auth.setUser(user);
                        this.notify(false, true);
                    },
                    error: (error) => {
                        this.snackBar.open(error.message, 'Ok', 5000);
                        this.notify(false, false);
                    },
                });
        } else {
            this.notify(false, false);
        }
    }

    notify(isValidating: boolean, isLoggedIn: boolean) {
        this._firebaseValidationState.validating = isValidating;
        this._firebaseValidationState.isLoggedIn = isLoggedIn;
        this._validatingTokenSubject.next(this._firebaseValidationState.validating);
    }

}

export interface FirebaseValidationState {
    validating: boolean;
    isLoggedIn: boolean;
}
